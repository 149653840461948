import React, { useState, useEffect } from "react";
import { RenderUserObj } from "../../helpers";
import { postFavorite } from "../../../api/requests";
import "./EventCard.module.scss";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { RenderImage, RenderDate } from "../../helpers";
import { Button, Modal } from "react-bootstrap";
import {
  updateGuestCount,
  updatePromoCode,
} from "../../../actions/guestBookActions";
import { showErrorToast, showSuccessToast } from "../../../utils/utils";

export default function EventCard(props) {
  let [loading, setLoading] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const auth = RenderUserObj(useSelector((state) => state.auth.data));
  const location = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  let userObj = useSelector((state) => state.auth.data);

  userObj = isAuthenticated ? JSON.parse(userObj) : [];

  const authState = useSelector((state) => state.auth);

  const handleImageClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const getBookingStatus = () => {
    if (props?.bookingStatus == 1) {
      return "Booking Closed";
    }
    if (props?.bookingStatus == 2) {
      return "Sold Out";
    }
  };

  const checkCurrentTime = (dateTime) => {
    const unixTime = moment(dateTime, "YYYY-MM-DD HH:mm").unix();
    const currentUnixTime = moment().unix();

    if (unixTime <= currentUnixTime) {
      return true;
    } else {
      return false;
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleFavoriteClick = async () => {
    let type = props?.isFavourite ? "Remove" : "Add";
    let response = await postFavorite(props.eventId, auth?.token, type);
    if (response?.status === "success") {
      showSuccessToast(response?.message ?? "Event removed from favorites");
      props?.handleFavoriteChange();
    } else {
      showErrorToast(
        response?.message ?? "Failed to remove event from favorites"
      );
    }
  };

  return (
    <React.Fragment>
      <div
        // onClick={scrollToTop}
        className={`position-relative shadow-sm ${props.classes}`}
        style={{ padding: "0 0 1rem 0", borderRadius: "8px", margin: "0 1rem" }}
      >
        {props.status == "live" ? (
          <Link
            className="link-unstyled"
            to={{
              pathname: `/parties/${props.eventId}`,
              state: props.event_sold_out,
              location: location.pathname,
            }}
            onClick={() => {
              dispatch(updateGuestCount(1));
              dispatch(updatePromoCode(""));
              scrollToTop();
            }}
          >
            <RenderImage
              loading={loading}
              setLoading={setLoading}
              imagesrc={props.images ? props.images[0] : null}
              classes="parties-heart"
              isSection={props.isSection}
              eventType={props.type}
            />

            {/* <div className="badgeCont">
              <div className="badgeIcon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="icon icon-tabler icons-tabler-outline icon-tabler-home-star"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M19.258 10.258l-7.258 -7.258l-9 9h2v7a2 2 0 0 0 2 2h4" />
                  <path d="M9 21v-6a2 2 0 0 1 2 -2h1.5" />
                  <path d="M17.8 20.817l-2.172 1.138a.392 .392 0 0 1 -.568 -.41l.415 -2.411l-1.757 -1.707a.389 .389 0 0 1 .217 -.665l2.428 -.352l1.086 -2.193a.392 .392 0 0 1 .702 0l1.086 2.193l2.428 .352a.39 .39 0 0 1 .217 .665l-1.757 1.707l.414 2.41a.39 .39 0 0 1 -.567 .411l-2.172 -1.138z" />
                </svg>

                <h4>{props.type}</h4>
              </div>
            </div> */}

            <div className="eventDetGap">
              <div className="locationNdate flexCenter">
                <div className="dateCont flexCenter">
                  <div className="iconSvg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                      <path d="M16 3l0 4" />
                      <path d="M8 3l0 4" />
                      <path d="M4 11l16 0" />
                      <path d="M8 15h2v2h-2z" />
                    </svg>
                  </div>
                  <p>{RenderDate(props.eventDate)}</p>
                </div>
                <div className="locationCont flexCenter">
                  <div className="iconSvg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                      <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                    </svg>
                  </div>
                  <p>
                    {props.location}
                    {","} {props.city}
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="flexColl">
                  <div className="eventTit">
                    <h3>{props.title}</h3>
                  </div>

                  <div className="hostedByDiv">Hosted by {props.hostName}</div>

                  <div className="eventPrice">
                    <h4>
                      {props.amount ? (
                        <span>&#x20b9; {props.amount}</span>
                      ) : (
                        "Free"
                      )}
                    </h4>
                  </div>
                </div>

                {props.bookingStatus != 0 && (
                  <p className="fw-bold price mb-0">
                    <span className="text-primary d-flex px-3 justify-content-end">
                      {getBookingStatus()}
                    </span>
                  </p>
                )}
              </div>

              {/* <div className="d-flex justify-content-between mt-3 badge-price px-3">
                <p className="mb-0">
                  <span
                    className="badge badge-primary px-2 text-uppercase"
                    style={{ borderRadius: "6px" }}
                  >
                    {props.type}
                  </span>
                </p>
                <span className="d-flex flex-column align-items-end">
                  <p className="fw-bold price mb-0">
                    {props.amount ? (
                      <span>&#x20b9; {props.amount}</span>
                    ) : (
                      "Free"
                    )}
                  </p>
                </span>
              </div> */}
            </div>
          </Link>
        ) : (
          <div onClick={handleImageClick}>
            <RenderImage
              loading={loading}
              setLoading={setLoading}
              imagesrc={props.images ? props.images[0] : null}
              classes="parties-heart"
              isSection={props.isSection}
              eventType={props.type}
            />
            {/* <div>
              <div className="d-flex justify-content-between mt-3 badge-price px-3">
                <p className="mb-0">
                  <span
                    className="badge badge-primary px-2 text-uppercase"
                    style={{ borderRadius: "6px" }}
                  >
                    {props.type}
                  </span>
                </p>
                <span className="d-flex flex-column align-items-end">
                  <p className="fw-bold price mb-0">
                    {props.amount ? (
                      <span>&#x20b9; {props.amount}</span>
                    ) : (
                      "Free"
                    )}
                  </p>
                </span>
              </div>
              <div className="d-flex justify-content-between">
                <h6 className="fw-bold px-3 my-2">{props.title}</h6>
                <p className="fw-bold price mb-0">
                  {props.membersLimit <= props.participantCount ? (
                    <span className="text-primary d-flex px-3 justify-content-end">
                      Sold Out
                    </span>
                  ) : (
                    <span className="text-primary d-flex px-3 justify-content-end">
                      {props.eventDate} {props.startTime}
                    </span>
                  )}
                </p>
              </div>
              <div className="d-flex justify-content-between timeplace text-muted px-3">
                <small>
                  {props.city}
                  {","} {props.state}
                </small>
                <small>{RenderDate(props.eventDate)}</small>
              </div>
            </div> */}
            <div className="eventDetGap">
              <div className="locationNdate flexCenter">
                <div className="dateCont flexCenter">
                  <div className="iconSvg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-calendar-event"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                      <path d="M16 3l0 4" />
                      <path d="M8 3l0 4" />
                      <path d="M4 11l16 0" />
                      <path d="M8 15h2v2h-2z" />
                    </svg>
                  </div>
                  <p>{RenderDate(props.eventDate)}</p>
                </div>
                <div className="locationCont flexCenter">
                  <div className="iconSvg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icons-tabler-outline icon-tabler-map-pin"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 11a3 3 0 1 0 6 0a3 3 0 0 0 -6 0" />
                      <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                    </svg>
                  </div>
                  <p>
                    {props.location}
                    {","} {props.city}
                  </p>
                </div>
              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="flexColl">
                  <div className="eventTit">
                    <h3>{props.title}</h3>
                  </div>

                  <div className="hostedByDiv">Hosted by {props.hostName}</div>

                  <div className="eventPrice">
                    <h4>
                      {props.amount ? (
                        <span>&#x20b9; {props.amount}</span>
                      ) : (
                        "Free"
                      )}
                    </h4>
                  </div>
                </div>

                {props.bookingStatus != 0 && (
                  <p className="fw-bold price mb-0">
                    <span className="text-primary d-flex px-3 justify-content-end">
                      {getBookingStatus()}
                    </span>
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
        {
          <Modal show={showPopup} onHide={handleClosePopup}>
            <Modal.Header closeButton>
              <Modal.Title>Event Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{ marginLeft: "70px", fontSize: "18px" }}>
                This event is either on Hold or Canceled...
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClosePopup}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        }
        {isAuthenticated && !props.additionalData ? (
          <FontAwesomeIcon
            icon={props?.isFavourite ? faHeartSolid : faHeart}
            size="2x"
            style={{ color: "red" }}
            className="favorite-icon"
            onClick={() => handleFavoriteClick()}
          ></FontAwesomeIcon>
        ) : null}
      </div>
    </React.Fragment>
  );
}
