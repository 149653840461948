import axios from 'axios';
import {
	AUTHENTICATE_USER_START,
	AUTHENTICATE_USER_SUCCESS,
	AUTHENTICATE_USER_FAILURE,
	BOOK_CLICKED_TRUE,
	BOOK_CLICKED_FALSE,
	LOAD_CURRENT_USER,
	UPDATE_SELECTED_CITY,
	UPDATE_IS_HOST,
} from '../actions/types';

import { NEW_URL } from '../api/requests';
import { axiosInstance } from '../api/config';
import Axios from 'axios';

export const setBookClickedTrue = () => ({
	type: BOOK_CLICKED_TRUE,
});

export const setBookClickedFalse = () => ({
	type: BOOK_CLICKED_FALSE,
});

export const authenticateUser = () => ({
	type: AUTHENTICATE_USER_START,
});

export const logoutUser = data => ({
	type: 'LOGOUT_USER',
	payload: {},
});

export const authenticateUserSuccess = data => ({
	type: AUTHENTICATE_USER_SUCCESS,
	payload: data,
});

export const authenticateUserFailure = errors => ({
	type: AUTHENTICATE_USER_FAILURE,
	payload: errors,
});

export const loadCurrentUser = errors => ({
	type: LOAD_CURRENT_USER,
	payload: errors,
});

export const updateCity = errors => ({
	type: UPDATE_SELECTED_CITY,
	payload: errors,
});
export const updateIsHost = errors => ({
	type: UPDATE_IS_HOST,
	payload: errors,
});

export const authenticateUserRequest = (response) => {
	return dispatch => {
		if (response.status !== "error") {
			response['token'] = response.token;
			const userData = JSON.stringify(response);
			dispatch(authenticateUserSuccess(userData));
			localStorage.setItem("tokenNew", response.token);
			axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
			Axios.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;

		} else {
			dispatch(authenticateUserFailure(response));
		}
		// axios
		// 	.post(`${NEW_URL}api/users/signInOTP`, {
		// 		phoneNumber: mobile,
		// 		otp: otp,
		// 	})
		// 	.then(response => {
		// 		if (response.data.status !== "error") {
		// 			response.data.data['token'] = response.data.token;
		// 			const userData = JSON.stringify(response.data.data);
		// dispatch(authenticateUserSuccess(userData));
		// 	} else {
		// 		dispatch(authenticateUserFailure(response.data));
		// 	}
		// })
		// .catch(errors => {
		// 	dispatch(authenticateUserFailure(errors));
		// });
	};
};

// export const getUserDetails = (token, userId) => {
// 	return dispatch => {
// 		axios
// 			.get(`${NEW_URL}api/users/${userId}`, {
// 				headers: {
// 					Authorization: `Bearer ${token}`,
// 				},
// 			})
// 			.then(response => {
// 				if (response.data.status !== "error") {
// 					response['token'] = response.token;
// 					const userData = JSON.stringify(response);
// 					dispatch(loadCurrentUser(userData));

// 				} else {
// 					dispatch(authenticateUserFailure(response.data));
// 				}
// 			})
// 			.catch(errors => {
// 				dispatch(authenticateUserFailure(errors));
// 			});
// 	};
// };

export const unloadCurrentUser = state => {
	return dispatch => {
		dispatch(logoutUser());
	};
};


